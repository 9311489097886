<template>
  <n-auth-panel>
    <template v-slot:heading>
      <n-html :text="$t('auth.register.title')" />
    </template>

    <template v-slot:content v-if="this.form_states.register" />

    <template v-slot:content v-else-if="easyRegistration">
      <n-html :text="$t('auth.register.email_subtitle')" />
    </template>

    <template v-slot:content v-else>
      <n-html :text="$t('auth.register.subtitle')" />
    </template>

    <div v-if="!this.form_states.register">
      <div v-if="easyRegistration">
        <n-form-group
          id="input-group-easyregister-email"
          :label="$t('form.easy_email.label')"
          label-for="input-easyregister-email"
          :state="form_states.easy_register_email"
          :invalid-feedback="easy_register_error"
          :info="$t('form.easy_email.info')"
        >
          <n-form-input-default
            id="input-easyregister-email"
            :placeholder="$t('form.easy_email.placeholder')"
            :value="easy_register_email"
            @input="value => (easy_register_email = value.toLowerCase())"
          ></n-form-input-default>
        </n-form-group>

        <div class="my-4">
          <n-checkbox
            id="input-easyregister-agreement"
            v-model="easy_register_agreement"
          >
            <p>
              <n-html :text="$t('auth.register.terms_and_conditions.text')" />
              <a
                class="underline text-brand-purple-500"
                :href="
                  $t('auth.register.terms_and_conditions.document_location')
                "
                target="_blank"
              >
                <n-html
                  :text="$t('auth.register.terms_and_conditions.url_text')"
                />
              </a>
            </p>
          </n-checkbox>
        </div>

        <div class="space-y-6">
          <n-form-group
            id="button-group-login"
            :invalid-feedback="$t('auth.login.error')"
            :state="form_states.register"
          >
            <n-button-regular
              :disabled="
                !easy_register_can_proceed ||
                  loading_states.easy_register_is_sending
              "
              full-width
              @click.native="registerWithEmail"
            >
              <n-html :text="$t('auth.register.button')" />
            </n-button-regular>
          </n-form-group>

          <n-html
            v-if="!!easy_register_success"
            :text="easy_register_success"
            class="mt-3 text-success block"
          ></n-html>

          <seperator>
            <n-html :text="$t('form.easy_email.or')" />
          </seperator>

          <n-button-regular
            full-width
            secondary
            @click.native="
              () => {
                easyRegistration = false;
              }
            "
          >
            <n-html :text="$t('form.easy_email.register_using_customer_no')" />
          </n-button-regular>
        </div>
      </div>

      <div v-else>
        <div class="flex flex-col space-y-4">
          <n-form-group
            id="input-group-contractnumber"
            :label="$t('form.contract_number.label')"
            label-for="input-contractnumber"
            :state="form_states.insurance_details_contract_number"
            :invalid-feedback="insurance_details_contract_number_error"
            :info="$t('form.contract_number.info')"
          >
            <n-form-input-default
              id="input-contractnumber"
              :placeholder="$t('form.contract_number.placeholder')"
              :value="insurance_details_contract_number"
              @input="
                value =>
                  (insurance_details_contract_number = value.toUpperCase())
              "
            ></n-form-input-default>
          </n-form-group>

          <n-form-group
            id="input-group-customernumber"
            :label="$t('form.relation_number.label')"
            label-for="input-customernumber"
            :state="form_states.insurance_details_relation_number"
            :info="$t('form.relation_number.info')"
          >
            <n-form-input-default
              id="input-customernumber"
              :placeholder="$t('form.relation_number.placeholder')"
              :value="insurance_details_relation_number"
              :disabled="form_states.insurance_details_contract_number"
              @input="
                value =>
                  (insurance_details_relation_number = value.toUpperCase())
              "
            ></n-form-input-default>
          </n-form-group>

          <n-form-group
            id="input-group-zipcode"
            :label="$t('form.zip_code.label')"
            label-for="input-customernumber"
            :invalid-feedback="insurance_details_combinationError"
            :state="form_states.insurance_details_relation_number"
          >
            <n-form-input-default
              id="input-zipcode"
              :placeholder="$t('form.zip_code.placeholder')"
              :value="insurance_details_zip_code"
              @input="
                value => (insurance_details_zip_code = value.toUpperCase())
              "
            ></n-form-input-default>
          </n-form-group>

          <n-form-group
            id="input-group-email"
            :label="$t('form.email.label')"
            label-for="input-email"
            :invalid-feedback="insurance_details_email_error"
            :state="form_states.insurance_details_email"
          >
            <n-form-input-default
              id="input-email"
              :placeholder="$t('form.email.placeholder')"
              v-model="insurance_details_email"
              :disabled="!form_states.insurance_details_relation_number"
              v-on:keyup.enter.native="registerWithInsurance"
            />
          </n-form-group>

          <n-form-group
            id="input-group-consent"
            :invalid-feedback="insurance_details_consent_error"
            :state="form_states.insurance_details_consent"
          >
            <n-checkbox id="input-consent" v-model="insurance_details_consent">
              <p>
                <n-html :text="$t('auth.register.terms_and_conditions.text')" />
                <a
                  class="underline text-brand-purple-500"
                  :href="
                    $t('auth.register.terms_and_conditions.document_location')
                  "
                  target="_blank"
                >
                  <n-html
                    :text="$t('auth.register.terms_and_conditions.url_text')"
                  />
                </a>
              </p>
            </n-checkbox>
          </n-form-group>
        </div>

        <n-form-group
          class="mt-11"
          id="button-group-register"
          :invalid-feedback="insurance_details_error"
          :state="form_states.register"
        >
          <n-button-regular
            :disabled="!insurance_details_can_proceed"
            full-width
            @click.native="registerWithInsurance"
          >
            <n-html :text="$t('auth.register.button')" />
          </n-button-regular>
        </n-form-group>
      </div>
    </div>
    <div v-else>
      <n-html :text="$t('auth.register.success')" />
      <div class="mt-4">
        <n-button-regular
          full-width
          @click.native="$router.push({ name: 'auth.login' }).catch(() => {})"
        >
          <n-html :text="$t('auth.register.back_to_login')" />
        </n-button-regular>
      </div>
    </div>
  </n-auth-panel>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import validation from "../../mixins/validation";
import Seperator from "../../components/theme/form/seperator";

export default {
  name: "pages.auth.register",
  components: { Seperator },
  mixin: [validation],
  data: () => ({
    easyRegistration: true,
    easy_register_email: "",
    easy_register_agreement: false,
    register_agreement: false,
    insurance_details_contract_number: "",
    insurance_details_relation_number: "",
    insurance_details_zip_code: "",
    insurance_details_email: "",
    insurance_details_consent: false,
    loading_states: {
      insurance_details_contract_number: false,
      insurance_details_relation_number: false,

      easy_register_is_sending: false
    },
    form_states: {
      insurance_details_contract_number: null,
      insurance_details_relation_number: null,
      easy_register_email: null,
      insurance_details_email: null,
      register: null,
      insurance_details_consent: null
    },
    easy_register_error: "",
    easy_register_success: "",

    insurance_details_combinationError: "",
    insurance_details_contract_number_error: "",
    insurance_details_email_error: "",
    insurance_details_error: "",
    insurance_details_consent_error: ""
  }),
  computed: {
    ...mapGetters({
      locale: "application/getLocale"
    }),
    easy_register_can_proceed() {
      return !!(
        this.form_states.easy_register_email && this.easy_register_agreement
      );
    },
    insurance_details_can_proceed() {
      return (
        this.form_states.insurance_details_relation_number &&
        this.form_states.insurance_details_email &&
        this.form_states.insurance_details_consent
      );
    }
  },
  methods: {
    verify_relation_number: _.debounce(function() {
      if (this.loading_states.insurance_details_relation_number) {
        return;
      }

      this.loading_states.insurance_details_relation_number = true;
      this.form_states.insurance_details_relation_number = null;

      this.$store
        .dispatch("customer/verify_relation_number", {
          relation_number: this.insurance_details_relation_number,
          zip_code: this.insurance_details_zip_code
        })
        .then(data => {
          if (!data.valid) {
            this.form_states.insurance_details_relation_number = false;
            this.insurance_details_combinationError = this.$t(
              "form.relation_number.feedback.invalid"
            );
            return;
          }

          if (data.in_use) {
            this.form_states.insurance_details_relation_number = false;
            this.insurance_details_combinationError = this.$t(
              "form.relation_number.feedback.in_use"
            );
            return;
          }

          this.form_states.insurance_details_relation_number = true;
        })
        .catch(
          () => (this.form_states.insurance_details_relation_number = false)
        )
        .finally(
          () => (this.loading_states.insurance_details_relation_number = false)
        );
    }, 500),
    verify_combination(n) {
      if (!n) {
        this.form_states.insurance_details_relation_number = false;
      } else if (this.insurance_details_relation_number) {
        this.verify_relation_number();
      }
    },
    verify_contract_number: _.debounce(function() {
      if (this.loading_states.insurance_details_contract_number) {
        return;
      }

      this.loading_states.insurance_details_contract_number = true;
      this.form_states.insurance_details_contract_number = null;

      this.$store
        .dispatch("contracts/verify_contract_number", {
          contract_number: this.insurance_details_contract_number
        })
        .then(data => {
          if (!data.external_id || !data.valid) {
            this.error = this.$t("form.contract_number.feedback.invalid");
            this.form_states.insurance_details_contract_number = false;
            return;
          }
          this.form_states.insurance_details_contract_number = true;
          this.insurance_details_relation_number = data.external_id
            ? data.external_id
            : "";
        })
        .catch(() => (this.form_states.insurance_details_contract_number = ""))
        .finally(
          () => (this.loading_states.insurance_details_contract_number = "")
        );
    }, 500),
    registerWithInsurance() {
      if (!this.insurance_details_consent) {
        this.form_states.register = false;
        this.insurance_details_consent_error = this.$t(
          "form.consent.feedback.invalid"
        );
        return;
      }

      if (this.insurance_details_can_proceed) {
        this.form_states.register = null;
        this.insurance_details_error = "";
        this.$store
          .dispatch("user/register", {
            relation_number: this.insurance_details_relation_number,
            zip_code: this.insurance_details_zip_code,
            email: this.insurance_details_email,
            locale: this.locale.substring(0, 2)
          })
          .then(() => (this.form_states.register = true))
          .catch(error => {
            this.insurance_details_error = this.$t(`errors.${error}`);
            this.form_states.register = false;
          });
      }
    },
    registerWithEmail() {
      this.form_states.easy_register_email = null;
      this.loading_states.easy_register_is_sending = true;
      this.easy_register_error = "";

      if (!this.easy_register_agreement) {
        this.form_states.register = false;
        this.easy_register_error = this.$t("errors.AGREEMENT_NOT_ACCEPTED");
        this.loading_states.easy_register_is_sending = false;

        return;
      }
      if (!this.easyRegistration) {
        this.form_states.register = false;
        this.easy_register_error = this.$t("form.email.feedback.invalid");
        this.loading_states.easy_register_is_sending = false;

        return;
      }
      this.$store
        .dispatch("user/create_by_email", {
          email: this.easy_register_email
        })
        .then(() => {
          this.form_states.easy_register_email = true;
          this.easy_register_success = this.$t(
            "form.easy_registration.success"
          );
        })
        .catch(error => {
          this.easy_register_error = error;
          this.form_states.easy_register_email = false;
        });

      this.loading_states.easy_register_is_sending = false;
    }
  },
  watch: {
    insurance_details_contract_number(n) {
      if (!n) {
        this.form_states.insurance_details_contract_number = false;
      } else {
        this.verify_contract_number();
      }
    },
    insurance_details_relation_number(n) {
      this.verify_combination(n);
    },
    insurance_details_zip_code(n) {
      this.verify_combination(n);
    },
    easy_register_email(n) {
      this.form_states.easy_register_email = n && this.validateEmail(n);

      if (!this.form_states.easy_register_email) {
        this.easy_register_error = this.$t("form.email.feedback.invalid");
      }
    },
    insurance_details_email(n) {
      this.form_states.insurance_details_email = n && this.validateEmail(n);

      if (!this.form_states.insurance_details_email) {
        this.insurance_details_email_error = this.$t(
          "form.email.feedback.invalid"
        );
      }
    },
    insurance_details_consent(n) {
      this.form_states.insurance_details_consent = n;
    }
  }
};
</script>
